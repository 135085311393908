 @import "bootstrap";
 @import "toastr";

@font-face {
    font-family: 'TeleGroteskNormal';
    src: url('/fonts/TeleGroteskNormal.eot');
    src: url('/fonts/TeleGroteskNormal.eot?#iefix') format('embedded-opentype'),
         /*url('/fonts/TeleGroteskNormal.woff2') format('woff2'),*/
         url('/fonts/TeleGroteskNormal.woff') format('woff'),
         url('/fonts/TeleGroteskNormal.ttf') format('truetype'),
         url('/fonts/TeleGroteskNormal.svg#tele-grotesknorregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'telegrotesk_headline_ultraRg';
    src: url('/fonts/TeleGroteskHeadline-Ultra.eot');
    src: url('/fonts/TeleGroteskHeadline-Ultra.eot?#iefix') format('embedded-opentype'),
      
         url('/fonts/TeleGroteskHeadline-Ultra.woff') format('woff'),
         url('/fonts/TeleGroteskHeadline-Ultra.ttf') format('truetype'),
         url('/fonts/TeleGroteskHeadline-Ultra.svg#telegrotesk_headline_ultraRg') format('svg');
    font-weight: normal;
    font-style: normal;

}



@font-face {
    font-family: 'tele-groteskfetregular';
    src: url('/fonts/telegroteskfett-webfont.eot');
    src: url('/fonts/telegroteskfett-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/telegroteskfett-webfont.woff2') format('woff2'),
         url('/fonts/telegroteskfett-webfont.woff') format('woff'),
         url('/fonts/telegroteskfett-webfont.ttf') format('truetype'),
         url('/fonts/telegroteskfett-webfont.svg#tele-groteskfetregular') format('svg');
    font-weight: normal;
    font-style: normal;

}


label {
	font-weight: normal!important;
}
.table > thead > tr > th, .table > thead > tr > td, .table > tbody > tr > th, .table > tbody > tr > td, .table > tfoot > tr > th, .table > tfoot > tr > td {
	border-top: 0;
}
.color-magenta { color: #ec008c; }

body {
	font-family: 'TeleGroteskNormal'!important;
	font-size: 16px;
}

a, a:hover, a:focus {
	text-decoration: none!important;
	color: #ec008c;
}
.btnSpec1 {
	color: #77787b;
	text-transform: uppercase;
}

.spacerTop1 {
	margin-top: 50px;
}

input[type="text"], textarea, .btn {
	border-radius: 0!important;
} 

.navTop1 {
	padding: 20px 0;
}
.navTopBtn {
	color: #929395;
	padding-bottom: 4px;
	border-bottom: 3px solid #fff;
	margin-left: 20px;
}
.navTopBtn:hover {
	color: #929395;
	padding-bottom: 4px;
	border-bottom: 3px solid #ec008c;
}

.btnFinalisti {
	margin-right: 20px;
	color: #ec008c;
	text-transform: uppercase;
	font-weight: bold;
}

.navTop2 {
	padding: 20px 0;
}

.masthead {
	padding: 20px 0 0 0;
}

.headerNav li a {
	color: #231f20;
	text-transform: uppercase;
	padding-top: 4px;
	border-top: 3px solid #fff;
	font-size: 20px;
}
.headerNav li a:hover, .headerNav .active {
	color: #231f20;
	text-transform: uppercase;
	padding-top: 4px;
	border-top: 3px solid #ec008c;
	background: #fff;
}

/* Footer */

.footer {
	background: #e7e7e8;
	margin-top: 20px;
}

.footerNav li a{
	color: #77787b;
	text-transform: uppercase;
	padding-bottom: 16px;
	border-bottom: 3px solid #e7e7e8;
	font-size: 20px;
	border-radius: 0;
	font-family: 'telegrotesk_headline_ultraRg';
}
.footerNav li a:hover, .footerNav .active {
	color: #77787b;
	text-transform: uppercase;
	padding-bottom: 16px;
	border-bottom: 3px solid #ec008c;
	background: #e7e7e8;

}

.neGasiti {
	padding: 0 0 45px 15px;
	color: #76787b;
}

.homeBoxTeme {
	margin-bottom: 30px;
}

.page-header1 {
	text-align: center;
	text-transform: uppercase;
	background: #e7e7e8;
	color: #77787b;
	padding: 5px 0;
	font-family: 'telegrotesk_headline_ultraRg';
	font-size: 20px;
	margin-top: 0;
}

.page-header2 {
	text-transform: uppercase;
	color: #ec008c;
	font-size: 22px;
}
.page-header3 {
	text-transform: uppercase;
	border-bottom: 1px solid #eeeeee;
    margin: 0 0 20px;
    padding-bottom: 9px;
    color: #77787b;
    font-size: 26px;
}
.page-header4 {
	text-align: center;
	text-transform: uppercase;
	background: #e7e7e8;
	color: #ec2b8c;
	padding: 5px 0;
	font-family: 'telegrotesk_headline_ultraRg';
}

.homeInfoContent {
	padding: 50px 0 35px 0; 
}

.page-header {
	color: #77787b;
	margin-top: 0;
	font-weight: normal	;
}

/* Pagination */
.pagination {
	margin-top: 50px;
}

.pagination > li > a, .pagination > li > span {
	border: 0!important;
	color: #eb3ea5;
}
.pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus, .pagination > .active > span, .pagination > .active > span:hover, .pagination > .active > span:focus {
	background: #eb3ea5;
	color: #fff;
}

.pagination > li > a:hover, .pagination > li > a:focus, .pagination > li > span:hover, .pagination > li > span:focus {
	color: #eb3ea5;
}

.btn-magenta {
	color: #fff;
	background: #ec008c;
	font-size: 24px;
	font-weight: bold;
	text-transform: uppercase;
}

.btn-file {
    position: relative;
    overflow: hidden;
}

.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
} 
.input-desc {
	color: #ec008c;
	padding-top: 5px;
	font-size: 14px;
}
.nsErrorMessages {
	padding-top: 15px;
}

.btn-newsletter {
	color: #fff;
	background: #77787b;
	text-transform: uppercase;
	font-weight: bold;
}
.btn-newsletter:hover {
	color: #fff;
	background: #77787b;
	text-transform: uppercase;
	text-decoration: underline;
	font-weight: bold;
}
.title-newsletter {
	font-size: 16px;
	text-transform: uppercase;
	padding-top: 5px;
}


.titluProiect {
	border-bottom: 1px solid #6d6e6d;
	color: #6d6e6d;
    font-weight: normal;
    margin-top: 0;
    text-transform: uppercase;
}
.initiatorProiect {
	color: #ef0077;
    font-weight: normal;
    margin-top: 0;
    text-transform: uppercase;
}

.infoProiect {
	padding-top: 20px;
}
.infoProiectTitlu {
	color: #000;
	font-size: 20px;
	font-weight: bold;
	text-transform: uppercase;
}

.titlu1 {
	border-bottom: 1px solid #6d6e6d;
	color: #6d6e6d;
    margin-top: 0;
}
.stea {
	color: #ec2b8c;
}
.infoProiectContent {
	font-family: arial!important;
	font-size: 14px;
}

.collapse.in {
	display: inline!important;
}

.fontArial {
	font-family: arial;
}








